const isEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const isPhoneComplete = (phone) => {
  phone = phone.replace(/[ |()\-+]/g, '')
  return /\d+$/.test(phone)
}

const isName = (name) => {
  const regex = /^([а-яА-Яa-zA-ZёЁ ()-]{2,50})+$/
  return regex.test(name)
}

export { isEmail, isPhoneComplete, isName }
