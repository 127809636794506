import { SUBSCRIBE_LIST_ID, SUBSCRIBE_URL } from '@/const/subscribe'
import { isEmail } from '@/utils/validator/validateRules'

export function addEmailToSubscribers(email, currentDomainData) {
  if (!isEmail(email)) {
    return false
  }

  const axios = require('axios-jsonp-pro')

  axios
    .jsonp(SUBSCRIBE_URL, {
      timeout: 0,
      params: {
        id: SUBSCRIBE_LIST_ID,
        CITY: currentDomainData.title,
        EMAIL: email,
      },
    })
    .then(function (response) {
      // eslint-disable-next-line no-console
      console.log(response)
    })
    .catch(function (error) {
      // eslint-disable-next-line no-console
      console.log('Можно игнорировать пока что...')
      // eslint-disable-next-line no-console
      console.log(error)
    })

  return true
}
